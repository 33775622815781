import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography, Grid, Box } from '@mui/material';
import useFetch from 'src/react-hydra-query/useFetch';
import { Add, Remove } from '@mui/icons-material';
import { useState } from 'react';
import PDF from 'react-pdf-js-infinite';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'src/reducers/clientScreen';
import { getDeviceId } from 'src/selectors/config';
import PropTypes from 'prop-types';
import { getAuthenticationHeaders } from 'src/selectors/authentication';
import SaleTermsLayout from './SaleTermsLayout';
import SaleTermsCheckbox from './SaleTermsCheckbox';

const SaleTermsScreen = ({ saleTermsId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const deviceId = useSelector(getDeviceId);
  const authenticationHeaders = useSelector((state) => getAuthenticationHeaders(state));

  const [zoom, setZoom] = useState(1);

  const { data: saleTerms, status: saleTermsStatus } = useFetch(
    { url: saleTermsId, headers: authenticationHeaders },
    { enabled: Boolean(saleTermsId) }
  );

  const { data: saleTermsFile, status: saleTermsFileStatus } = useFetch(
    {
      url: `${saleTerms?.documentId}`,
      responseType: 'blob',
    },
    { enabled: Boolean(saleTerms?.documentId) }
  );

  const isLoading = saleTermsStatus !== 'success' || saleTermsFileStatus !== 'success';

  const handleSignatureClick = () => {
    dispatch(change('sale_terms_signature', { saleTermsId }));
  };

  return (
    <SaleTermsLayout>
      <Grid container direction="column" sx={{ height: '100%' }} justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">
            <FormattedMessage
              id="sale-terms.title"
              values={{
                date: intl.formatDate(saleTerms?.updatedAt),
              }}
            />
          </Typography>
          <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #e0e0e0', my: 2 }} />
        </Grid>

        <Grid item>
          <Box
            sx={{
              height: '60vh',
              overflowY: 'auto',
              border: '1px solid #e0e0e0',
              borderRadius: 1,
            }}
          >
            {saleTermsFile && <PDF file={saleTermsFile} key={zoom} scale={zoom} />}
          </Box>
        </Grid>

        <Grid item container direction="column" sx={{ width: '100%' }}>
          <Grid item container alignItems="center" justifyContent="flex-end">
            <Button
              disabled={isLoading || zoom <= 0.5}
              sx={{ marginRight: 1 }}
              onClick={() => setZoom(zoom - 0.5)}
            >
              <Remove />
            </Button>
            <Button
              disabled={isLoading || zoom >= 3}
              sx={{ marginRight: 1 }}
              onClick={() => setZoom(zoom + 0.5)}
            >
              <Add />
            </Button>
          </Grid>
          <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #e0e0e0', my: 2 }} />
          <Grid item container justifyContent="flex-end">
            {saleTerms?.acceptanceMethod === 'checkbox' ? (
              <Grid item xs={12}>
                <SaleTermsCheckbox deviceId={deviceId} />
              </Grid>
            ) : (
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleSignatureClick}>
                  <FormattedMessage id="sale-terms.sign" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </SaleTermsLayout>
  );
};

SaleTermsScreen.propTypes = {
  saleTermsId: PropTypes.string.isRequired,
};

export default SaleTermsScreen;
