import { Grid, Typography, Box, Button, useTheme } from '@mui/material';
import { useState } from 'react';
import Sketchfield from '@stadline/mui-sketch-field';
import { FormattedMessage, useIntl } from 'react-intl';
import useSocketConnection from 'src/socket.io-react/useSocketConnection';
import { useSelector } from 'react-redux';
import { getDeviceId } from 'src/selectors/config';
import { getAuthenticationHeaders } from 'src/selectors/authentication';
import SaleTermsLayout from './SaleTermsLayout';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const SaleTermsSignatureScreen = () => {
  const theme = useTheme();
  const intl = useIntl();
  const authenticationHeaders = useSelector((state) => getAuthenticationHeaders(state));
  const deviceId = useSelector(getDeviceId);

  const [isWaiting, setIsWaiting] = useState(false);
  const [value, setValue] = useState(null);

  const handleMessage = (_, message) => {
    switch (message.type) {
      case 'sale_terms_signature_reset':
        setValue(null);
        setIsWaiting(false);
        break;
      default:
      // do nothing
    }
  };

  const broadcast = useSocketConnection(`/:clientToken/devices/${deviceId}`, handleMessage);

  const onSketchClear = () => {
    setValue(null);
  };

  const onChange = (val) => {
    setValue(val);
  };

  const onConfirm = async () => {
    setIsWaiting(true);
    broadcast('message', {
      type: 'sale_terms_signed',
      payload: value,
      headers: authenticationHeaders,
    });
    await delay(3000);
    setIsWaiting(false);
  };

  return (
    <SaleTermsLayout>
      <Grid
        container
        spacing={1}
        direction="column"
        style={{ height: '100%' }}
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Grid item style={{ paddingTop: theme.spacing(3) }}>
          <Typography variant="h6">
            <FormattedMessage id="sale-terms.signature.title" />
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            paddingTop: theme.spacing(2),
            maxHeight: '60vh',
          }}
        >
          <Sketchfield
            value={value}
            placeholder={intl.formatMessage({ id: 'mandate.signature.sketchfield.placeholder' })}
            width="100%"
            height="100%"
            onChange={onChange}
          />
          <Box width="100%" py={1} display="flex" justifyContent="flex-end">
            <Button color="primary" onClick={onSketchClear} variant="text">
              <FormattedMessage id="mandate.signature.delete-signature" />
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <Button
            color="primary"
            disabled={!value || isWaiting}
            onClick={onConfirm}
            variant="contained"
          >
            <FormattedMessage id="mandate.signature.confirm" />
          </Button>
        </Grid>
      </Grid>
    </SaleTermsLayout>
  );
};

export default SaleTermsSignatureScreen;
