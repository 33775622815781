import { Button, Checkbox, FormControlLabel, FormGroup, Typography, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import useSocketConnection from 'src/socket.io-react/useSocketConnection';
import { useDispatch } from 'react-redux';
import { reset } from 'src/reducers/clientScreen';
import PropTypes from 'prop-types';

const SaleTermsCheckbox = ({ deviceId }) => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleMessage = (_, message) => {
    switch (message.type) {
      case 'sale_terms_signed':
        setAcceptTerms(message.payload);
        break;
      default:
      // do nothing
    }
  };

  const broadcast = useSocketConnection(`/:clientToken/devices/${deviceId}`, handleMessage);

  const handleClick = () => {
    setAcceptTerms(true);
    broadcast('message', {
      type: 'sale_terms_acceptance_checked',
      payload: true,
    });
    dispatch(reset());
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
          }
          label={intl.formatMessage({ id: 'sale-terms.accept-terms-label' })}
        />
      </FormGroup>
      <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Typography variant="caption">
            <FormattedMessage id="sale-terms.accept-terms-quote" />
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" disabled={!acceptTerms} onClick={handleClick}>
            <FormattedMessage id="sale-terms.accept" />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SaleTermsCheckbox.propTypes = {
  deviceId: PropTypes.string.isRequired,
};

export default SaleTermsCheckbox;
